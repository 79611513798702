import React, { useRef, useCallback } from 'react';
import sendIcon from '../../../assets/new_icons/send.svg';
import { post } from '../../../services/api';
import Storage from '../../../utils/storage';
import { isMobile } from '../../../utils/browser-util';

import { GENERAL_CHAT_TAB } from './Chat';

const localStorage = Storage.getLocalStorage();

export default function ChatInput({ event, onMessageSent, isAdmin, isPrivateChat, activeChat, isPando }) {
  const _textareaRef = useRef(null);
  const _checkboxRef = useRef(null);

  const _sendMessage = useCallback(async () => {
    const message = _textareaRef.current.value;
    const toAll = _checkboxRef.current ? _checkboxRef.current.checked : false;

    if (message.length > 0) {
      try {
        let sendTo;
        let toParticipant = undefined;
        if (isPando) {
          if (activeChat.id === GENERAL_CHAT_TAB.id) {
            sendTo = isAdmin && toAll ? 'EVERYONE' : 'ADMINS';
          } else {
            sendTo = 'PARTICIPANT';
            toParticipant = activeChat.id;
          }
        } else {
          sendTo = 'EVERYONE';
        }
        const uuid = localStorage.getItem('uuid');
        const response = await post('/chatMessage', { event: event._id, fromParticipant: uuid, sendTo, message, toParticipant });
        onMessageSent && onMessageSent(response.data, true);
      } catch (err) {
        console.error(err);
      } finally {
        _textareaRef.current.value = '';
        if (_checkboxRef.current) {
          _checkboxRef.current.checked = false;
        }
      }
    }
  }, [activeChat, event, isAdmin, onMessageSent]);

  return (
    <div className='chat-input'>
      <textarea
        ref={_textareaRef}
        placeholder='Type your message here...'
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.stopPropagation();
            _sendMessage();
          }
        }}
        style={isMobile() ? { height: '25px' } : {}}
      />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <button onClick={_sendMessage}>
          <img src={sendIcon} />
        </button>
        {isPando && isAdmin && !isPrivateChat && (
          <div className='to-all'>
            <input ref={_checkboxRef} type='checkbox' />
            <label htmlFor='chat_to_all'>To All</label>
          </div>
        )}
      </div>
    </div>
  );
}
