import React, { useState, useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  browserName,
  browserVersion,
  isMobile,
  isIPad13,
} from "react-device-detect";
import GreenCheckIcon from "../../assets/icon-check-circle.svg";
import ErrorCircleIcon from "../../assets/icon-error-circle.svg";
import ChromeIcon from "../../assets/browser-chrome.svg";
import SafariIcon from "../../assets/browser-safari.svg";
import EdgeIcon from "../../assets/browser-edge.svg";
import FirefoxIcon from "../../assets/browser-firefox.svg";
import IeIcon from "../../assets/browser-ie.svg";
import OperaIcon from "../../assets/browser-opera.svg";
import TimeUtil from "../../utils/time-util";
import BrowserIcon from "./BrowserIcon";
import anim from "../../assets/animations/browser_icons.json";
import Spinner from "../../common/Spinner";

export default function BrowserCheck({
  title,
  loginLink,
  onComplete,
  onUpdateProgress,
  skipAnimation,
}) {
  const [browserSupported, setBrowserSupported] = useState(null);
  const [browserIcon, setBrowserIcon] = useState(null);
  const [browserString, setBrowserString] = useState("");
  const [showError, setShowError] = useState(false);
  const [showSupportedBrowserIcon, setShowSupportedBrowserIcon] =
    useState(false);

  useEffect(() => {
    (async () => {
      let checkPassed = false;
      switch (browserName) {
        case "Chrome":
          setBrowserIcon(ChromeIcon);
          setBrowserString("Google Chrome");
          checkPassed = true;
          break;
        case "Safari":
          if (browserVersion >= 13) {
            setBrowserIcon(SafariIcon);
            setBrowserString("Safari");
            checkPassed = true;
          } else {
            setBrowserIcon(SafariIcon);
            setBrowserString("Safari");
            checkPassed = false;
          }
          break;
        case "Mobile Safari":
          if (browserVersion >= 13) {
            setBrowserIcon(SafariIcon);
            setBrowserString("Safari");
            checkPassed = true;
          } else {
            setBrowserIcon(SafariIcon);
            setBrowserString("Safari");
            checkPassed = false;
          }
          break;
        case "Edge":
          if (browserVersion >= 80) {
            setBrowserIcon(EdgeIcon);
            setBrowserString("Edge");
            checkPassed = !isIPad13;
          } else {
            setBrowserIcon(EdgeIcon);
            setBrowserString("Edge");
            checkPassed = false;
          }
          break;
        case "Firefox":
          setBrowserIcon(FirefoxIcon);
          setBrowserString("Firefox");
          checkPassed = false;
          break;
        case "IE":
          setBrowserIcon(IeIcon);
          setBrowserString("Internet Explorer");
          checkPassed = false;
          break;
        case "Opera":
          setBrowserIcon(OperaIcon);
          setBrowserString("Opera");
          checkPassed = false;
          break;
        default:
          checkPassed = false;
          break;
      }

      setBrowserSupported(checkPassed);
    })();
  }, []);

  useEffect(() => {
    if (skipAnimation) {
      if (browserSupported === true) {
        onUpdateProgress({ isCompatibleBrowser: true });
        onComplete && onComplete();
      } else if (browserSupported === false) {
        onUpdateProgress({ isCompatibleBrowser: false });
        setShowError(true);
        onComplete && onComplete(false);
      }
    }
  }, [browserSupported, skipAnimation]);

  const _onAnimationEvent = async (event) => {
    if (event === "complete") {
      if (browserSupported === true) {
        onUpdateProgress({ isCompatibleBrowser: true });
        setShowSupportedBrowserIcon(true);
        await TimeUtil.sleep(2000);
        onComplete && onComplete();
      } else if (browserSupported === false) {
        onUpdateProgress({ isCompatibleBrowser: false });
        setShowError(true);
        onComplete && onComplete(false);
      }
    }
  };

  return (
    <div style={{ width: "100%", height: "calc(100% - 45px)" }}>
      <h3>
        {browserSupported === false && showError
          ? "Unsupported Browser"
          : browserSupported && showSupportedBrowserIcon
          ? "Found Supported Browser"
          : title}
      </h3>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        {!showError && !skipAnimation && (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Player
              autoplay
              speed={1.5}
              onEvent={_onAnimationEvent}
              src={anim}
              style={{
                height: "160px",
                width: "160px",
                opacity: showSupportedBrowserIcon ? 0 : 1,
                marginBottom: 55,
              }}
            />
            <div
              className="fade-in"
              style={{
                width: 200,
                position: "absolute",
                zIndex: 9,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                opacity: showSupportedBrowserIcon ? 1 : 0,
              }}
            >
              <img src={browserIcon} alt="browser" />
              <p>{browserString}</p>
            </div>
          </div>
        )}
        {!showError && skipAnimation && (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Spinner />
          </div>
        )}
        {showError && (
          <table cellSpacing={3} style={{ width: "100%" }}>
            <tbody>
              <tr style={{ backgroundColor: "rgb( 31, 43, 55)" }}>
                <td style={{ verticalAlign: "top", padding: 10, fontSize: 12 }}>
                  Browser Check
                </td>
                <td style={{ verticalAlign: "top", padding: 10 }}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <BrowserIcon icon={browserIcon} name={browserString} />
                      </div>
                      <div
                        style={{
                          fontSize: 11,
                          backgroundColor: "rgb(72, 29, 37)",
                          margin: 5,
                          padding: "7px 10px",
                          borderRadius: 20,
                        }}
                      >
                        {isMobile
                          ? "Mobile devices are not supported except for Safari on iPads"
                          : "Your browser is not supported"}
                      </div>
                    </div>
                    <div style={{ fontSize: 13, marginTop: 25 }}>
                      Supported browser list:
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: 10,
                      }}
                    >
                      {!isMobile && (
                        <BrowserIcon icon={ChromeIcon} name="Google Chrome" />
                      )}
                      <BrowserIcon icon={SafariIcon} name="Safari" />
                      {!isMobile && (
                        <BrowserIcon icon={EdgeIcon} name="Microsoft Edge" />
                      )}
                    </div>
                  </div>
                </td>
                <td
                  style={{
                    verticalAlign: "top",
                    padding: 10,
                    textAlign: "center",
                  }}
                >
                  {browserSupported ? (
                    <img src={GreenCheckIcon} alt={""} width={24} />
                  ) : (
                    <img src={ErrorCircleIcon} alt={""} width={24} />
                  )}
                </td>
              </tr>
              {!browserSupported && (
                <tr style={{ backgroundColor: "rgb( 31, 43, 55)" }}>
                  <td
                    style={{ verticalAlign: "top", padding: 10, fontSize: 12 }}
                  >
                    Login Info
                  </td>
                  <td style={{ verticalAlign: "top", padding: 10 }}>
                    <div>
                      <div
                        style={{ fontSize: 13, marginTop: 0, marginBottom: 40 }}
                      >
                        Please use a supported browser from the list above.
                        Then, right-click to copy{" "}
                        <a
                          href={loginLink}
                          style={{
                            color: "#fff",
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          this login URL
                        </a>{" "}
                        and paste it into the other browser.
                      </div>
                    </div>
                  </td>
                  <td />
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
