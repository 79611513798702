import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import MediaDeviceUtil from '../../utils/media-device-util';
import SocketClient from '../../utils/socket-client';
import MediaDevicesPopover from './MediaDevicesPopover';
import Storage from '../../utils/storage';
import LocalMedia from './LocalMedia';
import Tabs from './Tabs';
import Poll from './Poll/Poll';
import { OFF_WALL_ADMIN, OFF_WALL_PARTICIPANT, OBSERVER, VENUE_PARTICIPANT, VENUE_OFF_WALL } from '../../utils/user-util';
import { store } from '../../store';
import { isIpad, isMobile } from '../../utils/browser-util';
import { put } from '../../services/api';
import Deferred from '../../utils/deferred';
import CollapseButton from '../janusBreakoutRoom/CollapseButton';

const localStorage = Storage.getLocalStorage();

let _videoStream;
let _audioStream;

function ConnectForm(
  {
    connected,
    onSwitchTrack,
    status,
    photoUrl,
    photoTaken,
    onAcceptPhoto,
    onChangeInput,
    participantData,
    isOffWall,
    isObserver,
    poll,
    onClosePoll,
    isOnMeetingBreak,
    isOffWallReady,
    isBroadcast,
    isVenueParticipant,
    children,
  },
  ref,
) {
  const _videoRef = useRef(null);
  const _stateRef = useRef(null);

  const [onAir, setOnAir] = useState(false);
  const [audioInput, setAudioInput] = useState(null);
  const [audioOutput, setAudioOutput] = useState(null);
  const [videoInput, setVideoInput] = useState(null);
  const [resolution, setResolution] = useState(null);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [videoInputDevices, setVideoInputDevices] = useState([]);
  const [supportedResolutions, setSupportedResolutions] = useState([]);

  const [hideSideBar, setHideSideBar] = useState(false);
  const [showCollapseButton, setShowCollapseButton] = useState(false);

  // ComponentDidMount
  useEffect(() => {
    (async () => {
      const {
        router: {
          location: { state: participantInfo },
        },
      } = store.getState();
      // Redux store is not updated right away upon browser refresh, thus read from localStorage
      const role = participantInfo.role;
      const _isOffWall = role === OFF_WALL_ADMIN || role === OFF_WALL_PARTICIPANT || role === OBSERVER || role === VENUE_OFF_WALL;
      const _isBroadcast = participantInfo.event.eventType === 'BROADCAST';

      let audioinput, audiooutput, videoinput;
      if (!_isOffWall && !_isBroadcast && role !== VENUE_PARTICIPANT) {
        await MediaDeviceUtil.getUserMedia({ audio: true, video: true }, true);
        const devices = await _getAvailableDevices(false);
        audioinput = devices.audioinput;
        audiooutput = devices.audiooutput;
        videoinput = devices.videoinput;
        setAudioOutputDevices(audiooutput);
        setAudioInputDevices(audioinput);
        setVideoInputDevices(videoinput);

        window.navigator.mediaDevices.addEventListener('devicechange', _updateMediaDevices);

        let storedAudioInput;
        let storedAudioOutput;
        let storedVideoInput;
        let storedResolution;
        try {
          storedAudioInput = localStorage.getItem('audioInput') ? JSON.parse(localStorage.getItem('audioInput')) : null;
          storedAudioOutput = localStorage.getItem('audioOutput') ? JSON.parse(localStorage.getItem('audioOutput')) : null;
          storedVideoInput = localStorage.getItem('videoInput') ? JSON.parse(localStorage.getItem('videoInput')) : null;
          storedResolution = localStorage.getItem('resolution') ? JSON.parse(localStorage.getItem('resolution')) : null;
        } catch (error) {
          // Ignore parse errors for now
        }

        let validAudioOutput = false;
        let validVideoInput = false;
        let validAudioInput = false;
        if (storedResolution) {
          setResolution(storedResolution);
        }

        const defaultAudioOutput = audiooutput.find((dev) => dev.isDefault === true) || audiooutput[0];
        if (storedAudioOutput) {
          validAudioOutput = audiooutput.find((dev) => dev.deviceId === storedAudioOutput.deviceId);
          if (validAudioOutput) {
            setAudioOutput(storedAudioOutput);
          } else {
            setAudioOutput(defaultAudioOutput);
          }
        } else {
          setAudioOutput(defaultAudioOutput);
        }

        const defaultVideoInput = videoinput.find((dev) => dev.isDefault === true) || videoinput[0];
        if (storedVideoInput) {
          validVideoInput = videoinput.find((dev) => dev.deviceId === storedVideoInput.deviceId);
          if (validVideoInput) {
            setVideoInput(validVideoInput);
          } else {
            setVideoInput(defaultVideoInput);
          }
        } else {
          setVideoInput(defaultVideoInput);
        }

        const defaultAudioInput = audioinput.find((dev) => dev.isDefault === true) || audioinput[0];
        if (storedAudioInput) {
          validAudioInput = audioinput.find((dev) => dev.deviceId === storedAudioInput.deviceId);
          if (validAudioInput) {
            setAudioInput(validAudioInput);
          } else {
            setAudioInput(defaultAudioInput);
          }
        } else {
          setAudioInput(defaultAudioInput);
        }
      }
    })();
    return () => {
      if (_audioStream) {
        _audioStream.getTracks().forEach((track) => track.stop());
      }
      if (_videoStream) {
        _videoStream.getTracks().forEach((track) => track.stop());
      }
      window.navigator.mediaDevices.removeEventListener('devicechange', _updateMediaDevices);
    };
  }, []);

  useEffect(() => {
    if (!_stateRef.current) _stateRef.current = {};
    _stateRef.current = { videoInput, audioInput, audioOutput, connected };
  });

  useEffect(() => {
    if (status) {
      if (status === 'ON_AIR') {
        setOnAir(true);
      } else {
        setOnAir(false);
      }
    }
  }, [status]);

  useEffect(() => {
    (async () => {
      if (videoInput) {
        try {
          if (_videoStream) {
            _videoStream.getTracks().forEach((track) => track.stop());
          }

          localStorage.removeItem('useSafeResolution');
          const supportedResolutions = await MediaDeviceUtil.getSupportedResolutions(videoInput.deviceId);
          const resolutionsList = supportedResolutions.map((res) => ({ label: res, value: res }));
          setSupportedResolutions(resolutionsList);

          const selectedResolution = resolution && resolution !== null && supportedResolutions.includes(resolution.value) ? resolution : resolutionsList[0];
          const parts = selectedResolution.value.split('x');
          const width = parseInt(parts[0]);
          const height = parseInt(parts[1]);

          _videoStream = await MediaDeviceUtil.getUserMedia({
            video: { deviceId: { exact: videoInput.deviceId }, width: { exact: width }, height: { exact: height } },
          });

          if (!_videoRef.current) {
            const videoRefDeferred = new Deferred();
            const timerId = setInterval(() => {
              if (_videoRef.current) {
                clearInterval(timerId);
                videoRefDeferred.resolve();
              }
            }, 250);
            await videoRefDeferred.promise;
          }

          _videoStream.getTracks().forEach((track) => {
            const mediaStream = new MediaStream();
            mediaStream.addTrack(track);
            if (track.kind === 'video' && _videoRef.current) {
              _videoRef.current.srcObject = mediaStream;
            }
          });

          if (connected) {
            const newVideoTrack = _videoStream.getVideoTracks()[0];
            onSwitchTrack('video', newVideoTrack);
            SocketClient.emit('message', {
              type: 'update-resolution-list',
              data: { resolutionsList, selectedResolution },
            });
          }

          _videoRef.current.onloadeddata = () => {
            MediaDeviceUtil.videoStreamInitialized.resolve();
          };

          window.videoStream = _videoStream;
          setResolution(selectedResolution);
          localStorage.setItem('videoInput', JSON.stringify(videoInput));
        } catch (error) {
          console.log('applyVideoInputChange error', error);
        }
      }
    })();
  }, [videoInput]);

  useEffect(() => {
    (async () => {
      if (resolution && _videoStream) {
        try {
          await MediaDeviceUtil.videoStreamInitialized.promise;
          const parts = resolution.value.split('x');
          const width = parseInt(parts[0]);
          const height = parseInt(parts[1]);
          const constraints = {
            width: { exact: width },
            height: { exact: height },
          };

          // If iPad, add constraint { facingMode: 'user' }
          if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
            constraints.facingMode = 'user';
          }

          const track = _videoStream.getVideoTracks()[0];
          await track.applyConstraints(constraints);
          localStorage.setItem('resolution', JSON.stringify(resolution));
        } catch (error) {
          console.log('unsupported resolution for device', resolution, error);
        }
      }
    })();
  }, [resolution]);

  const _changeAudioTrack = async (audioInput) => {
    try {
      let constraints;

      if (isIpad) {
        constraints = {
          audio: true,
        };
      } else {
        constraints = {
          audio: {
            channelCount: 1,
            sampleSize: { exact: 16 },
            autoGainControl: true,
            volume: 1,
            latency: 0,
            deviceId: { exact: audioInput.deviceId },
          },
        };
      }
      _audioStream = await MediaDeviceUtil.getUserMedia(constraints);

      const { connected } = _stateRef.current;
      if (connected) {
        if (window.audioStream) {
          window.audioStream.getTracks().forEach((track) => track.stop());
        }
        const newAudioTrack = _audioStream.getAudioTracks()[0];
        onSwitchTrack('audio', newAudioTrack);
      }

      window.audioStream = _audioStream;
      MediaDeviceUtil.audioStreamInitialized.resolve();
      localStorage.setItem('audioInput', JSON.stringify(audioInput));

      if (isIpad && !window.ipadHackTimerId) {
        window.ipadHackTimerId = setInterval(() => {
          const audioInput = localStorage.getItem('audioInput') ? JSON.parse(localStorage.getItem('audioInput')) : null;
          if (audioInput) {
            _changeAudioTrack(audioInput);
          }
        }, 60000);
      }
    } catch (error) {
      console.log('applyAudioInputChange error', error);
    }
  };

  useEffect(() => {
    if (audioInput) {
      _changeAudioTrack(audioInput);
    }
  }, [audioInput]);

  useEffect(() => {
    if (audioOutput) {
      try {
        const timerId = setInterval(() => {
          const audio = document.getElementById('remote-audio');
          if (audio) {
            audio.setSinkId(audioOutput.deviceId);
            clearInterval(timerId);
            localStorage.setItem('audioOutput', JSON.stringify(audioOutput));
          }
        }, 500);
      } catch (error) {
        console.log('applyAudioOutputChange error', error);
      }
    }
  }, [audioOutput]);

  async function _updateMediaDevices() {
    const { audioinput, audiooutput, videoinput } = await _getAvailableDevices(false);
    setAudioOutputDevices(audiooutput);
    setAudioInputDevices(audioinput);
    setVideoInputDevices(videoinput);

    const { videoInput, audioInput, audioOutput, connected } = _stateRef.current;

    let selectedAudioInput = audioInput;
    let selectedAudioOutput = audioOutput;
    let selectedVideoInput = videoInput;

    if (videoInput !== null) {
      const videoInputExists = videoinput.find((dev) => dev.deviceId === videoInput.deviceId);
      if (!videoInputExists && videoinput.length > 0) {
        const device = videoinput.find((dev) => dev.isDefault === true) || videoinput[0];
        setVideoInput(device);
        selectedVideoInput = device;
      }
    }

    if (audioInput !== null) {
      const audioInputExists = audioinput.find((dev) => dev.deviceId === audioInput.deviceId);
      if (!audioInputExists && audioinput.length > 0) {
        const device = audioinput.find((dev) => dev.isDefault === true) || audioinput[0];
        setAudioInput(device);
        selectedAudioInput = device;
      }
    }

    if (audioOutput !== null) {
      const audioOutputExists = audiooutput.find((dev) => dev.deviceId === audioOutput.deviceId);
      if (!audioOutputExists && audiooutput.length > 0) {
        const device = audiooutput.find((dev) => dev.isDefault === true) || audiooutput[0];
        setAudioOutput(device);
        selectedAudioOutput = device;
      }
    }

    if (connected) {
      SocketClient.emit('message', {
        type: 'update-media-devices',
        data: { audioInputs: audioinput, audioOutputs: audiooutput, videoInputs: videoinput, selectedAudioInput, selectedAudioOutput, selectedVideoInput },
      });
    }
  }

  async function _getAvailableDevices(cached = true) {
    const availableDevices = await MediaDeviceUtil.getAvailableDevices(cached);
    const kinds = ['audioinput', 'audiooutput', 'videoinput'];
    const devicesMap = {};
    kinds.forEach((kind) => {
      devicesMap[kind] = availableDevices.filter((dev) => dev.kind === kind);
    });
    return devicesMap;
  }

  async function _updateParticipantDevices(data) {
    try {
      const uuid = localStorage.getItem('uuid');
      await put(`/participant/${uuid}`, data);
    } catch (error) {
      console.log(error);
    }
  }

  function _onVideoInputChange(device) {
    setResolution(null);
    setVideoInput(device);
    _updateParticipantDevices({ 'selectedDevices.videoInput': device });
    if (connected) {
      SocketClient.emit('message', {
        type: 'update-video-input',
        data: device,
      });
    }
  }

  function _onResolutionChange(resolution) {
    setResolution(resolution);
    const parts = resolution.value.split('x');
    const width = parseInt(parts[0]);
    const height = parseInt(parts[1]);
    _updateParticipantDevices({ 'selectedDevices.videoInput.width': width, 'selectedDevices.videoInput.height': height });
    if (connected) {
      SocketClient.emit('message', {
        type: 'update-resoluton',
        data: resolution,
      });
    }
  }

  function _onAudioInputChange(device) {
    setAudioInput(device);
    _updateParticipantDevices({ 'selectedDevices.audioInput': device });
    if (connected) {
      SocketClient.emit('message', {
        type: 'update-audio-input',
        data: device,
      });
    }
  }

  function _onAudioOutputChange(device) {
    setAudioOutput(device);
    _updateParticipantDevices({ 'selectedDevices.audioOutput': device });
    if (connected) {
      SocketClient.emit('message', {
        type: 'update-audio-output',
        data: device,
      });
    }
  }

  async function setupSocketClient(url, uuid, eventId, studioId, token) {
    if (!SocketClient.socket) {
      await SocketClient.setup(url, uuid, eventId, studioId, token, isOffWall || isObserver || isBroadcast);
    }
    SocketClient.joinRoom(uuid);
    SocketClient.joinRoom(`event-${eventId}`);

    SocketClient.addListener('change-input', async (payload) => {
      let _data = null;
      if (payload.type === 'videoinput') {
        localStorage.setItem('videoInput', JSON.stringify(payload.data));
        setVideoInput(payload.data);
        _data = { videoInput: payload.data };
      } else if (payload.type === 'resolution') {
        localStorage.setItem('resolution', JSON.stringify(payload.data.resolution));
        setResolution(payload.data.resolution);
      } else if (payload.type === 'audioinput') {
        localStorage.setItem('audioInput', JSON.stringify(payload.data));
        setAudioInput(payload.data);
        _data = { audioInput: payload.data };
      } else if (payload.type === 'audiooutput') {
        localStorage.setItem('audioOutput', JSON.stringify(payload.data));
        setAudioOutput(payload.data);
        _data = { audioOutput: payload.data };
      } else if (payload.type === 'participantInformation') {
        const { name, location } = payload.data;
        localStorage.setItem('participantName', name);
        localStorage.setItem('participantLocation', location);
        _data = { name, location };
      }
      onChangeInput && onChangeInput(_data);
    });

    // SocketClient.addListener('client-command', async ({ type, value }) => {
    //   let constraints = null;

    //   if (type === 'mic-volume') {
    //     constraints = { volume: parseFloat(value), autoGainControl: false, echoCancellation: true, noiseSuppression: true };
    //   }
    //   if (constraints) {
    //     try {
    //       const currentTrack = _audioStream.getAudioTracks()[0];
    //       let trackConstraints = currentTrack.getConstraints();

    //       Object.entries(constraints).forEach(([k, v]) => {
    //         trackConstraints[k] = v;
    //       });

    //       _audioStream = await MediaDeviceUtil.getUserMedia({ audio: trackConstraints });
    //       const track = _audioStream.getAudioTracks()[0];

    //       if (onSwitchTrack('audio', track) === true) {
    //         currentTrack.stop();
    //       }
    //     } catch (error) {
    //       console.log('client-command error', error);
    //     }
    //   }
    // });
  }

  useImperativeHandle(ref, () => ({
    setupSocketClient(url, uuid, eventId, studioId, token) {
      setupSocketClient(url, uuid, eventId, studioId, token);
    },
  }));

  useEffect(() => {
    const handleOrientationChange = (event) => {
      const orientation = event.detail.orientation;
      if (orientation === 'portrait') {
        setHideSideBar(false);
        setShowCollapseButton(false);
      } else {
        setShowCollapseButton(true);
      }
    };

    window.addEventListener("orientation-change", handleOrientationChange);
    return () => {
      window.removeEventListener("orientation-change", handleOrientationChange);
    };
  }, []);

  const skipPhotoModal = localStorage.getItem('skipPhotoModal') === 'true';

  if (isOffWall === null || isVenueParticipant === null || isObserver) return null;

  const enableChat = participantData && participantData.event && participantData.event.enableChat;
  const enableQnA = participantData && participantData.event && participantData.event.enableQNAWidget;
  const enableNotes = participantData && participantData.event && participantData.event.enableNotes;
  let showLeftPanel = !isBroadcast || enableChat || enableQnA || enableNotes;

  const htmlWidget = participantData && participantData.event && participantData.event.htmlWidget;
  const showHtmlWidget = htmlWidget && htmlWidget.enabled;
  if (isBroadcast && isMobile()) {
    showLeftPanel = enableQnA || showHtmlWidget;
  }

  const style = photoTaken
    ? {
        width: showLeftPanel || poll ? (isVenueParticipant ? '100%' : 320) : 0,
      }
    : {
        width: '100%',
        marginBottom: '8%',
        padding: '30px 40px',
        backgroundColor: '#233140',
        maxWidth: 700,
        height: 360,
        transform: window.innerHeight < 550 ? 'scale(0.8)' : 'scale(1)',
        marginTop: window.innerHeight < 550 ? '5%' : 0,
        display: skipPhotoModal ? 'none' : 'block',
      };

  const showTabs = !isMobile() || !poll;

  return (
    <div className={'sidebar'} style={hideSideBar ? { width: 20, left: -300 } : { ...style, left: 0 }}>
      {isMobile() && !isBroadcast && showCollapseButton ? <CollapseButton value={hideSideBar} onClick={() => setHideSideBar(!hideSideBar)} style={{ left: 305 }} /> : null}
      {!isOffWall && !isVenueParticipant && !isBroadcast && (
        <LocalMedia
          ref={{
            videoRef: _videoRef,
          }}
          onAir={onAir}
          status={status}
          mediaDevices={
            <MediaDevicesPopover
              videoInput={videoInput}
              resolution={resolution}
              audioInput={audioInput}
              audioOutput={audioOutput}
              videoInputDevices={videoInputDevices}
              supportedResolutions={supportedResolutions}
              audioInputDevices={audioInputDevices}
              audioOutputDevices={audioOutputDevices}
              onVideoInputChange={_onVideoInputChange}
              onResolutionChange={_onResolutionChange}
              onAudioInputChange={_onAudioInputChange}
              onAudioOutputChange={_onAudioOutputChange}
            />
          }
          photoUrl={photoUrl}
          photoTaken={photoTaken}
          videoInputDevices={videoInputDevices}
          videoInput={videoInput}
          onVideoInputChange={_onVideoInputChange}
          onAcceptPhoto={onAcceptPhoto}
          isOnMeetingBreak={isOnMeetingBreak}
          uiVersion='photo-modal'
          participantData={participantData}
        />
      )}
      {photoTaken && participantData && status !== 'REMOVED' && (
        <>
          {showLeftPanel && showTabs && <Tabs event={participantData.event} participantData={participantData} />}
          <Poll
            poll={poll}
            eventParticipant={participantData}
            onClose={onClosePoll}
            isOffWall={isOffWall}
            isOffWallReady={isOffWallReady}
            showLeftPanel={showLeftPanel}
            isVenueParticipant={isVenueParticipant}
          />
        </>
      )}
      {children}
    </div>
  );
}

export default forwardRef(ConnectForm);
