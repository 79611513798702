import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { post } from '../../services/api';

import Input from '../../common/Input';
import Button from '../../common/Button';

const styles = {
  inputContainer: { margin: 5 },
  input: { fontSize: 16, marginBottom: 15, marginTop: 5 },
  button: { width: '100%', margin: '30px 0 15px 0', fontSize: 16 },
};

function LoginForm({ eventId, onSuccessfulLogin }) {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');

  const handleInputChange = useCallback((e) => {
    const { value, name } = e.target;
    if (name === 'email') {
      setEmailError('');
      setEmail(value);
    } else if (name === 'firstName') {
      setFirstNameError('');
      setFirstName(value);
    } else if (name === 'lastName') {
      setLastNameError('');
      setLastName(value);
    }
  }, []);

  const handleVenueLogin = useCallback(async (e) => {
    try {
      e.preventDefault();
      if (!email) {
        setEmailError('Email is required');
        return;
      }
      const { data: loginData } = await post('/auth/venueLogin', { email, eventId });
      const { registered, ...rest } = loginData;
      if (registered === true) {
        onSuccessfulLogin(rest);
      } else {
        setShowRegisterForm(true);
      }
    } catch (error) {
      console.error('venue login', error);
    }
  }, [eventId, email, onSuccessfulLogin]);

  const handleVenueRegistration = useCallback(async (e) => {
    try {
      e.preventDefault();
      
      let hasError = false;
      if (!firstName) {
        setFirstNameError('First name is required');
        hasError = true;
      }
      if (!lastName) {
        setLastNameError('Last name is required');
        hasError = true;
      }

      if (hasError) {
        return;
      }

      const { data: registrationData } = await post('/auth/venueRegistration', { email, firstName, lastName, eventId });
      onSuccessfulLogin(registrationData);
    } catch (error) {
      console.error('venue registration', error);
    }
  }, [ email, firstName, lastName, eventId, onSuccessfulLogin]);

  if (showRegisterForm) {
    return (
      <form onSubmit={handleVenueRegistration} noValidate>
      <Input
        type='text'
        name='email'
        label='Email'
        value={email}
        onChange={handleInputChange}
        style={styles.input}
        containerStyle={styles.inputContainer}
        hasError={emailError.length > 0}
        errorMessage={emailError}
        stacked
      />
      <Input
        type='text'
        name='firstName'
        label='First Name'
        value={firstName}
        onChange={handleInputChange}
        style={styles.input}
        containerStyle={styles.inputContainer}
        hasError={firstNameError.length > 0}
        errorMessage={firstNameError}
        stacked
      />
      <Input
        type='text'
        name='lastName'
        label='Last Name'
        value={lastName}
        onChange={handleInputChange}
        style={styles.input}
        containerStyle={styles.inputContainer}
        hasError={lastNameError.length > 0}
        errorMessage={lastNameError}
        stacked
      />
      <Button text='Continue' type='submit' onClick={handleVenueRegistration} containerStyle={styles.button} />
    </form>
    )
  }

  return (
    <form onSubmit={handleVenueLogin} noValidate>
      <Input
        type='text'
        name='email'
        label='Email'
        value={email}
        onChange={handleInputChange}
        style={styles.input}
        containerStyle={styles.inputContainer}
        hasError={emailError.length > 0}
        errorMessage={emailError}
        stacked
      />
      <Button text='Continue' type='submit' onClick={handleVenueLogin} containerStyle={styles.button} />
    </form>
  );
}

LoginForm.propTypes = {
  onSuccessfulLogin: PropTypes.func.isRequired,
};

export default LoginForm;
