import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import ChatMessage from './ChatMessage';
import ChatInput from './ChatInput';
import ChatTabs from './ChatTabs';
import TabHeader from './TabHeader';
import { get, post, put } from '../../../services/api';
import { EVENT_SUPPORT, WALL_PRESENTER, OFF_WALL_ADMIN, OFF_WALL_PARTICIPANT } from '../../../utils/user-util';
import Storage from '../../../utils/storage';
import InfiniteScroll from '../../../common/InfiniteScroll';
import ContextMenu from '../../../common/ContextMenu';
import { isMobile } from '../../../utils/browser-util';
import './Chat.css';

const localStorage = Storage.getLocalStorage();

export const GENERAL_CHAT_TAB = {
  id: 'everyone',
  label: 'General',
};

function Chat({ event, role, show, allowESChatforWallPresenters, onChatTabSelected, isPando, hideAttendeeDetails, isVenueParticipant }, ref) {
  const uuid = localStorage.getItem('uuid');
  const infiniteScrollRef = useRef(null);
  const roleRef = useRef(null);

  const _isMobile = isMobile();

  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

  const [messages, setMessages] = useState(null);
  const [activeChat, setActiveChat] = useState(GENERAL_CHAT_TAB);
  const [highlightedChatTabs, setHighlightedChatTabs] = useState([]);
  const [activeChatMessages, setActiveChatMessages] = useState([]);
  const [chatTabs, setChatTabs] = useState([]);

  useImperativeHandle(ref, () => ({
    addMessage: _addMessage,
    addMessages: _addMessages,
    startPrivateChat: _startPrivateChat,
    openChatTab: _openChatTab,
    highlightChatTab: _highlightChatTab,
    clearTextChat: _clearTextChat,
    updateMessage: _updateMessage,
  }));

  const _fetchChatMessages = async () => {
    try {
      const { data } = await get(`/chatMessage?event=${event._id}&uuid=${uuid}`);
      const _chatTabs = [];
      for (const [key, value] of Object.entries(data)) {
        let tab = { id: key, label: GENERAL_CHAT_TAB.label };
        if (key !== GENERAL_CHAT_TAB.id) {
          const participant = key === value[0].toParticipant._id ? value[0].toParticipant : value[0].fromParticipant;
          const { firstName, lastName } = participant;
          tab.label = `${firstName || ''} ${lastName || ''}`.trim();
        }
        _chatTabs.push(tab);
      }
      setChatTabs(_chatTabs);
      setMessages(data);
      setActiveChatMessages(data.everyone);
      onChatTabSelected && onChatTabSelected(GENERAL_CHAT_TAB);

      if (infiniteScrollRef.current) {
        infiniteScrollRef.current.scrollToBottom();
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    _fetchChatMessages();
  }, []);

  useEffect(() => {
    (async () => {
      if (roleRef.current && roleRef.current !== role) {
        await _fetchChatMessages();
        if (role !== EVENT_SUPPORT) {
          _closeChatTabs();
        }
      }
      roleRef.current = role;
    })();
  }, [role]);

  useEffect(() => {
    if (show) {
      if (infiniteScrollRef.current) {
        infiniteScrollRef.current.scrollToBottom();
      }
    }
  }, [show]);

  useEffect(() => {
    if (activeChat) {
      const _messages = { ...messages };
      if (!_messages[activeChat.id]) {
        _messages[activeChat.id] = [];
      }
      const _activeChatMessages = [..._messages[activeChat.id]];
      setActiveChatMessages(_activeChatMessages);
      setMessages(_messages);
      if (infiniteScrollRef.current) {
        infiniteScrollRef.current.scrollToBottom();
      }
      onChatTabSelected && onChatTabSelected(activeChat);
    }
  }, [activeChat]);

  const _startPrivateChat = ({ uuid, firstName, lastName }) => {
    const _chatTabs = [...chatTabs];
    let _activeChat = _chatTabs.find((c) => c.id === uuid);
    if (!_activeChat) {
      _activeChat = {
        id: uuid,
        label: `${firstName || ''} ${lastName || ''}`.trim(),
      };
      _chatTabs.push(_activeChat);
      setChatTabs(_chatTabs);
    }
    setActiveChat(_activeChat);
  };

  const _getNextActiveChat = (msg) => {
    const { fromParticipant, sendTo } = msg;
    let nextActiveChat;
    if (sendTo === 'PARTICIPANT') {
      nextActiveChat = chatTabs.find((tab) => tab.id === fromParticipant._id);
      if (!nextActiveChat) {
        const { _id, firstName, lastName } = fromParticipant;
        nextActiveChat = {
          id: _id,
          label: `${firstName || ''} ${lastName || ''}`.trim(),
        };
        const _chatTabs = [...chatTabs];
        _chatTabs.push(nextActiveChat);
        setChatTabs(_chatTabs);
      }
    } else {
      nextActiveChat = GENERAL_CHAT_TAB;
    }
    return nextActiveChat;
  };

  const _closeChatTabs = () => {
    const _messages = { ...messages };
    chatTabs.forEach((tab) => {
      if (tab.id !== GENERAL_CHAT_TAB.id) {
        delete _messages[tab.id];
      }
    });

    setMessages(_messages);
    setChatTabs([{ id: 'everyone', label: GENERAL_CHAT_TAB.label }]);
    setActiveChat(GENERAL_CHAT_TAB);
    onChatTabSelected && onChatTabSelected(GENERAL_CHAT_TAB);
  };

  const _openChatTab = (msg) => {
    const _activeChat = _getNextActiveChat(msg);
    const tabIndex = highlightedChatTabs.findIndex((hc) => hc.id === _activeChat.id);
    if (tabIndex !== -1) {
      const _highlightedChatTabs = [...highlightedChatTabs];
      _highlightedChatTabs.splice(tabIndex, 1);
      setHighlightedChatTabs(_highlightedChatTabs);
    }
    setActiveChat(_activeChat);
  };

  const _highlightChatTab = (msg) => {
    const _activeChat = _getNextActiveChat(msg);
    const tabIndex = highlightedChatTabs.findIndex((hc) => hc.id === _activeChat.id);
    if (tabIndex === -1) {
      const _highlightedChatTabs = [...highlightedChatTabs];
      _highlightedChatTabs.push(_activeChat);
      setHighlightedChatTabs(_highlightedChatTabs);
    }
  };

  const _addMessage = (msg, sentByMe = false) => {
    const _messages = { ...messages };
    const _activeChatMessages = [...activeChatMessages];

    if (msg.sendTo === 'PARTICIPANT') {
      const { _id } = sentByMe ? msg.toParticipant : msg.fromParticipant;
      if (!_messages[_id]) {
        _messages[_id] = [];
      }
      _messages[_id].push(msg);
      if (activeChat && activeChat.id === _id) {
        _activeChatMessages.push(msg);
      }
    } else {
      if (!_messages[GENERAL_CHAT_TAB.id]) {
        _messages[GENERAL_CHAT_TAB.id] = [];
      }
      _messages[GENERAL_CHAT_TAB.id].push(msg);
      if (activeChat && activeChat.id === GENERAL_CHAT_TAB.id) {
        _activeChatMessages.push(msg);
      }
    }
    setMessages(_messages);
    setActiveChatMessages(_activeChatMessages);

    if (sentByMe && infiniteScrollRef.current) {
      infiniteScrollRef.current.scrollToBottom();
    }
  };

  const _addMessages = (msgs) => {
    const _messages = { ...messages };
    const _activeChatMessages = [...activeChatMessages];

    msgs.forEach((msg) => {
      if (msg.sendTo === 'PARTICIPANT') {
        const { _id } = msg.fromParticipant;
        if (!_messages[_id]) {
          _messages[_id] = [];
        }
        _messages[_id].push(msg);
        if (activeChat && activeChat.id === _id) {
          _activeChatMessages.push(msg);
        }
      } else {
        if (!_messages[GENERAL_CHAT_TAB.id]) {
          _messages[GENERAL_CHAT_TAB.id] = [];
        }
        _messages[GENERAL_CHAT_TAB.id].push(msg);
        if (activeChat && activeChat.id === GENERAL_CHAT_TAB.id) {
          _activeChatMessages.push(msg);
        }
      }
    });

    setMessages(_messages);
    setActiveChatMessages(_activeChatMessages);
  };

  const _updateMessage = (msg) => {
    const _activeChatMessages = [...activeChatMessages];
    const _messages = { ...messages };

    let index = _messages[GENERAL_CHAT_TAB.id].findIndex((m) => m._id === msg._id);
    if (index !== -1) {
      _messages[GENERAL_CHAT_TAB.id].splice(index, 1, msg);
      setMessages(_messages);
    }

    if (activeChat && activeChat.id === GENERAL_CHAT_TAB.id) {
      index = _activeChatMessages.findIndex((m) => m._id === msg._id);
      if (index !== -1) {
        _activeChatMessages.splice(index, 1, msg);
        setActiveChatMessages(_activeChatMessages);
      }
    }
  };

  const _clearTextChat = () => {
    const _messages = { ...messages };
    if (activeChat && activeChat.id === GENERAL_CHAT_TAB.id) {
      setActiveChatMessages([]);
    }
    _messages.everyone = [];
    setMessages(_messages);
    if (infiniteScrollRef.current) {
      infiniteScrollRef.current.clear();
    }
  };

  const _onTabChange = (tab) => {
    setActiveChat(tab);
    const tabIndex = highlightedChatTabs.findIndex((hc) => hc.id === tab.id);
    if (tabIndex !== -1) {
      const _highlightedChatTabs = [...highlightedChatTabs];
      _highlightedChatTabs.splice(tabIndex, 1);
      setHighlightedChatTabs(_highlightedChatTabs);
    }
    onChatTabSelected && onChatTabSelected(tab);
  };

  const _onTabClose = (tab) => {
    const _chatTabs = [...chatTabs];
    const _messages = { ...messages };

    const highlightedTabIndex = highlightedChatTabs.findIndex((hc) => hc.id === tab.id);
    if (highlightedTabIndex !== -1) {
      const _highlightedChatTabs = [...highlightedChatTabs];
      _highlightedChatTabs.splice(highlightedTabIndex, 1);
      setHighlightedChatTabs(_highlightedChatTabs);
    }

    const chatTabIndex = _chatTabs.findIndex((c) => c.id === tab.id);
    if (chatTabIndex !== -1) {
      _chatTabs.splice(chatTabIndex, 1);
      delete _messages[tab.id];
      setMessages(_messages);
      setChatTabs(_chatTabs);
      if (activeChat.id === tab.id) {
        setActiveChat(GENERAL_CHAT_TAB);
        onChatTabSelected && onChatTabSelected(tab);
      }
    }
  };

  const _onContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowContextMenu(true);
    setContextMenuPosition({ x: e.pageX, y: e.pageY });
  };

  const _onContextMenuItemSelection = async (option) => {
    switch (option.value) {
      case 1:
        try {
          await post(`/chatMessage/clear`, { event: event._id });
          _clearTextChat();
        } catch (e) {
          console.error(e);
        }
        break;
      default:
        break;
    }
  };

  const _hideContextMenu = () => {
    setShowContextMenu(false);
    setContextMenuPosition({ x: 0, y: 0 });
  };

  const _onCheckMarkClick = async (messageId) => {
    try {
      await put(`/chatMessage/${messageId}`, { acknowledged: true });
    } catch (e) {
      console.error(e);
    }
  };

  const isGeneralChat = activeChat.id === GENERAL_CHAT_TAB.id;
  const isAdmin = role === EVENT_SUPPORT || role === OFF_WALL_ADMIN || (role === WALL_PRESENTER && allowESChatforWallPresenters);

  let _classes = '';
  if (role === OFF_WALL_ADMIN || role === OFF_WALL_PARTICIPANT) {
    if (isGeneralChat) {
      _classes = isAdmin ? 'message-history off-wall-admin' : 'message-history off-wall';
    } else {
      _classes = 'message-history off-wall private';
    }
  } else {
    if (isGeneralChat) {
      _classes = isAdmin && role !== WALL_PRESENTER ? 'message-history admin' : 'message-history';
    } else {
      _classes = 'message-history private';
    }
  }

  return (
    <div className='chat' style={{ display: show ? 'flex' : 'none', flexDirection: 'column', height: '100%', width: isVenueParticipant || (_isMobile && isPando) ? '100%' : 320 }}>
      {isPando && isGeneralChat && role === EVENT_SUPPORT && showContextMenu === true && (
        <ContextMenu
          position={contextMenuPosition}
          items={[{ value: 1, label: 'Clear Text Chat', danger: true }]}
          onClose={_hideContextMenu}
          onItemSelected={_onContextMenuItemSelection}
        />
      )}
      {!isGeneralChat && <TabHeader activeChat={activeChat} event={event} hideAttendeeDetails={hideAttendeeDetails} />}
      {isPando && isGeneralChat && (
        <TabHeader
          isPrivateChat={false}
          text='This is a private support chat. Please type your messages for the support staff below.'
          hideAttendeeDetails={hideAttendeeDetails}
        />
      )}
      {!isPando && (
        <TabHeader
          isPrivateChat={false}
          text='Messages entered here will be viewable by all participants of this broadcast.'
          hideAttendeeDetails={hideAttendeeDetails}
        />
      )}
      <InfiniteScroll
        ref={infiniteScrollRef}
        items={activeChatMessages}
        renderItem={(msg) => {
          const fromOtherParticipant = msg.fromParticipant._id !== uuid;

          if (msg.sendTo === 'ADMINS' && !isAdmin) {
            if (fromOtherParticipant) {
              return null;
            }
          }

          let name = `${msg.fromParticipant.firstName} ${msg.fromParticipant.lastName}`.trim();
          if (hideAttendeeDetails) {
            name = `${msg.fromParticipant.firstName}`.trim();
          }

          return (
            <ChatMessage
              key={`msg-${msg._id}`}
              from={name}
              message={msg.message}
              messageTime={msg.createdAt}
              sendTo={msg.sendTo}
              type={msg.type}
              acknowledged={msg.acknowledged}
              isAdmin={isAdmin}
              enableCheckMark={isPando && isGeneralChat && isAdmin && fromOtherParticipant}
              onCheckMarkClick={() => _onCheckMarkClick(msg._id)}
            />
          );
        }}
        containerClassName={_classes}
        onContextMenu={_onContextMenu}
      />
      <div className='chat-input-container'>
        {isPando && (
          <ChatTabs
            chats={chatTabs}
            activeChat={activeChat}
            highlightedChatTabs={highlightedChatTabs}
            onTabChange={_onTabChange}
            onTabClose={_onTabClose}
            hideAttendeeDetails={hideAttendeeDetails}
          />
        )}
        <ChatInput event={event} onMessageSent={_addMessage} isAdmin={isAdmin} isPrivateChat={!isGeneralChat} activeChat={activeChat} isPando={isPando} />
      </div>
    </div>
  );
}

export default forwardRef(Chat);
